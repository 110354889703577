<template>
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_961_67928"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="20"
      height="24"
    >
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint0_linear_961_67928)"
      />
    </mask>
    <g mask="url(#mask0_961_67928)">
      <path
        d="M18.3824 24H1.61765C0.724246 24 0 23.2325 0 22.2857V1.71429C0 0.767512 0.724246 0 1.61765 0H12.5L20 7.94805V22.2857C20 23.2325 19.2757 24 18.3824 24Z"
        fill="url(#paint1_linear_961_67928)"
      />
      <g opacity="0.23" filter="url(#filter0_f_961_67928)">
        <g style="mix-blend-mode: darken" opacity="0.23">
          <path d="M19.0442 11.6104H1.10303V18.1558H19.0442V11.6104Z" fill="#E32E06" />
        </g>
      </g>
      <g filter="url(#filter1_d_961_67928)">
        <path
          d="M20 7.94805H14.1176C13.2243 7.94805 12.5 7.18054 12.5 6.23377V0L20 7.94805Z"
          fill="url(#paint2_linear_961_67928)"
        />
      </g>
      <path
        d="M8.0166 14.2568H6.3291V16H5.4502V11.7344H8.22754V12.4463H6.3291V13.5479H8.0166V14.2568ZM9.61621 15.2939H11.4824V16H8.7373V11.7344H9.61621V15.2939ZM12.9619 14.9424L13.9287 11.7344H14.9072L13.4219 16H12.5049L11.0254 11.7344H12.001L12.9619 14.9424Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_961_67928"
        x="-37.897"
        y="-27.3896"
        width="95.9412"
        height="84.5454"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="19.5" result="effect1_foregroundBlur_961_67928" />
      </filter>
      <filter
        id="filter1_d_961_67928"
        x="-10.5"
        y="-24"
        width="57.5"
        height="57.948"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="1" />
        <feGaussianBlur stdDeviation="12.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_961_67928" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_961_67928"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_961_67928"
        x1="10"
        y1="0"
        x2="10"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF7979" />
        <stop offset="1" stop-color="#E85555" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_961_67928"
        x1="10"
        y1="0"
        x2="10"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF8777" />
        <stop offset="1" stop-color="#F0695F" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_961_67928"
        x1="16.25"
        y1="0"
        x2="16.25"
        y2="7.94805"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#DA5D4C" />
        <stop offset="1" stop-color="#E32900" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AI",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
</style>
